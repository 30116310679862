#home-main {
  margin-top: 100px;
}

.hero-background {
  background: url("green-table-layout-up-close_2800x705.9ef2c867.webp") top no-repeat fixed;
}

.hero-box {
  color: #e6e6e6;
  background-color: #000000b3;
  margin: 0;
  padding: 12px 36px;
}

.hero-box p {
  font-size: 1.222rem;
  font-weight: 200;
}

.hero-separater {
  width: 35%;
  border-bottom: 3px solid #e6e6e6;
  margin-left: auto;
  margin-right: auto;
}

.card-padding {
  padding: 32px 0;
}

.option-box {
  background-color: #f2f5f5;
  margin-bottom: 36px;
  overflow: auto;
}

.small-box {
  text-align: center;
  padding: 24px;
}

.small-container h2 {
  color: #930345;
  margin-top: 5px;
  line-height: 1.1;
}

.pdf-box {
  margin: 0 50px;
  overflow: auto;
}

.small-pdf {
  color: #fff;
  margin-top: 5%;
}

.help-section {
  background-image: linear-gradient(135deg, #f9cb3a, #f99b19);
  padding: 32px 0;
}

.mini-option {
  padding: 0 15px;
}

.updated {
  float: none;
  clear: both;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
}

.updated button {
  padding: 8px 16px;
}

.updated input {
  width: 50%;
  padding: 8px 16px;
}

@media only screen and (max-width: 992px) {
  .hero-box h1, .small-container h2 {
    font-size: 1.444rem;
  }

  .hero-box p, .small-container p, .small-container button, .pdf-box p, .pdf-box button {
    font-size: 1rem;
  }

  .hero-box, .option-box {
    margin: 0;
  }

  .small-box {
    height: 250px;
    padding: 60px 50px;
    display: table;
  }

  .small-container {
    vertical-align: middle;
    margin: 0;
    display: table-cell;
  }

  .small-container p, .small-pdf p {
    font-size: .889rem;
  }

  .small-container p {
    width: 50%;
  }

  .mini-option p {
    width: 100%;
  }

  .small-container p, .mini-option p {
    margin: auto auto 24px;
  }

  .small-box-button {
    padding: 8px 16px;
    font-size: .889rem;
  }

  .card-padding {
    padding: 0;
  }

  .pdf-box h2 {
    font-size: 1.333rem;
  }
}

@media only screen and (max-width: 600px) {
  .hero-image {
    background: url("craps-table-layout-mobile_600x316.037b052f.webp") top no-repeat fixed;
  }

  .hero-box h1 {
    font-size: 1.444rem;
  }

  .hero-box p {
    font-size: .889rem;
  }

  .small-pdf {
    margin: 0;
  }

  .small-container p, .pdf-box h2, .pdf-box p {
    width: 80%;
  }

  .pdf-box h2, .pdf-box p {
    margin-left: auto;
    margin-right: auto;
  }

  .pdf-box .w3-col {
    padding-top: 0 !important;
  }

  .mini-option {
    margin-top: 25px;
    padding: 0 48px;
  }

  .mini-option p {
    width: 75%;
  }
}

@media (prefers-color-scheme: dark) {
  .option-box {
    background-color: #0f0f0f;
  }

  .small-container h2 {
    color: #a74165;
  }

  .help-section {
    background-image: linear-gradient(#3d3d3d, #2a2a2a);
  }
}

/*# sourceMappingURL=index.4fed2cda.css.map */
