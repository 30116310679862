@charset "UTF-8";
#home-main {margin-top: 100px;}
.hero-background {background: url("../img/home-page/green-table-layout-up-close_2800x705.webp") no-repeat fixed top center;}
.hero-box {background-color: rgba(0,0,0,0.70);color:#E6E6E6;padding: 12px 36px;margin: 0px;}
.hero-box p {font-size: 1.222rem;font-weight: 200;}
.hero-separater {border-bottom: 3px solid #E6E6E6;width: 35%;margin-left: auto;margin-right: auto;}
.card-padding {padding: 32px 0px;}
.option-box {margin-bottom: 36px;overflow: auto;background-color: #F2F5F5;}
.small-box {padding: 24px;text-align: center;}
.small-container h2 {margin-top: 5px;line-height: 1.1;color: #930345;}
.pdf-box {margin: 0px 50px;overflow: auto;}
.small-pdf {margin-top: 5%; color: white;}
.help-section {padding: 32px 0px; background-image: linear-gradient(135deg, #F9CB3A, #F99B19);}
.mini-option {padding: 0px 15px;}
.updated {margin-left: auto; margin-right: auto; float: none; clear: both; padding:24px;}
.updated button {padding: 8px 16px;}
.updated input {padding: 8px 16px; width: 50%;}

     /* Tablet */
@media only screen and (max-width: 992px) {
.hero-box h1 {font-size: 1.444rem;}
.small-container h2 {font-size: 1.444rem;}
.hero-box p, .small-container p, .small-container button, .pdf-box p, .pdf-box button{font-size: 1rem;}
.hero-box {margin: 0px;}
.option-box {margin: 0px;}
.small-box {height: 250px;padding: 60px 50px;display: table;}
.small-container {margin: 0px;display: table-cell;vertical-align: middle;}
.small-container p, .small-pdf p {font-size: .889rem;}
.small-container p {width: 50%;}
.mini-option p {width: 100%;}
.small-container p, .mini-option p {margin: auto; margin-bottom: 24px;}
.small-box-button {font-size: .889rem;padding: 8px 16px;}
.card-padding {padding: 0px;}
.pdf-box h2 {font-size: 1.333rem;}
}

     /*Mobile*/
@media only screen and (max-width: 600px) {
.hero-image {background: url("../img/home-page/craps-table-layout-mobile_600x316.webp") no-repeat fixed top center;}
.hero-box h1 {font-size: 1.444rem;}
.hero-box p {font-size: .889rem;}
.small-pdf {margin: 0;}
.small-container p, .pdf-box h2, .pdf-box p {width: 80%;}
.pdf-box h2, .pdf-box p {margin-left: auto; margin-right:auto;}
.pdf-box .w3-col {padding-top:0px!important;}
.mini-option {padding: 0px 48px; margin-top: 25px;}
.mini-option p {width: 75%;}
}

     /*Dark Mode*/
@media (prefers-color-scheme: dark) {
.option-box {background-color: #0F0F0F;}
.small-container h2 {color: #A74165;}
.help-section {background-image: linear-gradient(180deg, #3d3d3d, #2a2a2a);}
}